import axios from 'axios'
import { convertToFormData } from './use-form-data-converter'
import { objectToQueryString } from './query-string-parser'

// define all api methods, get, post, put and delete
// if options' isFormData set true then data will convert to FormData format

export const get = ({ url, options = {} }) => {
  return axios.get(`${url}?${objectToQueryString(options)}`)
}

export const postApiForm = ({ url, type, form, options }) => {
  return callApiForm({ method: 'post', url, form, options })
}

export const putApiForm = ({ url, type, form, options }) => {
  return callApiForm({ method: 'put', type, url, form, options })
}

export const destroy = ({ url }) => {
  return axios.delete(url)
}

const callApiForm = ({ url, method, type, form, options = {} }) => {
  return new Promise(async (resolve, reject) => {
    let apiData = {
      data: {
        type: type,
        attributes: form
      }
    }
    let apiHeader = axios.defaults.headers

    if (options.isFormData) {
      apiData = convertToFormData(apiData)
      apiHeader = Object.assign(apiHeader, {
        'Content-Type': 'multipart/form-data'
      })
    }

    try {
      const response = await axios({
        method: method,
        url: url,
        data: apiData,
        headers: apiHeader
      })

      resolve(response)
    } catch (errors) {
      reject(errors)
    }
  })
}
